import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { PageProvider } from '../context/page'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
}) => {
    return (
        <PageProvider>
            {element}
        </PageProvider>
    )
}
